<template>
  <div class="page">
    <top />
    <div style="width: 100%; height: 50px"></div>
    <el-carousel style="margin-bottom: 10px" height="98px">
      <el-carousel-item v-for="item in banner" :key="item">
        <a :href="`http://` + item.url">
          <img
            style="width: 100%"
            :src="item.litpic"
            alt=""
          />
        </a>
      </el-carousel-item>
    </el-carousel>
    <div class="mid2">
		<el-select
			class="select"
			multiple 
			v-model="format_checkeds"
			@change="changeformat"
			placeholder="选择格式"
		>
			<el-option
			  :label="itm"
			  :value="itm"
			  v-for="(itm, idx) in format_arr"
			  :key="idx"
			></el-option>
		</el-select>
      <el-select
		class="select"
        v-model="choose_type[index]"
        :placeholder="item.typename"
        v-for="(item, index) in arctype"
        :key="index"
        @change="change"
      >
        <el-option label="全部" value=""></el-option>
        <el-option
          v-for="(itemm, indexx) in item.son"
          :key="indexx"
          :label="itemm.typename"
          :value="itemm.id"
        >
        </el-option>
      </el-select>
	  <el-select
		class="select"
	    v-model="show_model"
	    @change="changemodel"
	  >
	    <el-option
	      label="图片模式"
	      value="1"
	    ></el-option>
	    <el-option
	      label="样衣模式"
	      value="2"
	    ></el-option>
	  </el-select>
    </div>
    <div class="bottom2">
      <div class="bottomimg">
        <div
          class="bottomimg2"
          v-for="(item, index) in list"
          :key="index"
          @click="go('Details', item.aid)"
        >
          <img
            v-if="item.sales_num >= hot_limit"
            class="poa"
            src="../../assets/bao.png"
            alt=""
          />
          <img :src="imageUrl(item.litpic)" alt="" v-if="show_model=='1'"/>
		  <div class="yiwrap" v-if="show_model=='2'">
		  	<img class="closeimg" :src="imageUrl(item.clothingTem)" alt="" v-if="item.clothingTem">
		  	<div v-if="item.repeat == 1" :id="'img'+item.aid" class="logo" :style="{
		  	  'z-index':'1',
		  	  position: 'absolute', 
		  	  width: '100%',
		  	  height: '100%',
		  	  backgroundImage: 'url(' + imageUrl(item.imgs) + ')',
		  	  backgroundSize: '39px',
		  	}"></div>
		  	<img v-if="item.repeat != 1" :id="'img'+item.aid" class="logo1" :src="imageUrl(item.imgs)" alt=""/>
		  	<div class="bj" :style="{ backgroundColor: item.color }"></div>
		  </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="size"
          layout="total, prev, pager, next ,jumper"
          :pager-count="5"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>
    <info />
    <div style="width: 100%; height: 60px; background-color: #f6f6f6"></div>
    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import info from "../../components/common/qrCode.vue";
import {
  openAreaData,
  checkOriginalAuth,
  webConfigAd,
} from "../../request/api";
export default {
  components: {
    top,
    foot,
    info,
  },
  data() {
    return {
      value1: "",
      value: "",
      count: 30,
      size: 20,
      page: 1,
      arctype: "", //分类
      choose_type: [],
      list: "",
      banner: [],
	  show_model:'1',
	  format_checkeds:[],
	  format_arr:[]
    };
  },
  created() {
    // console.log(this.$route.query.keysWord);
    if (this.$store.getters.getQuery.length !== 0) {
      this.choose_type = this.$store.getters.getQuery;
    }
    if (this.$store.getters.getPage.length !== 0) {
      this.page = this.$store.getters.getPage;
      console.log(this.page);
    }
    this.getData();
  },
  methods: {
    //获取数据
    getData() {
      openAreaData({
        type: this.$route.query.keysWord,
        choose_type: this.choose_type.toString(),
		formats:this.format_checkeds.toString(),
        page: this.page,
        size: this.size,
      }).then((res) => {
        // console.log(res)
        if (res.code == 301) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.page = 1;
        } else {
		  for(var i=0;i<res.data.archivesInfo.length;i++){
			  res.data.archivesInfo[i].imgs = res.data.archivesInfo[i].imgs.replace(/\s/g,'%20');
		  }
		  this.list = res.data.archivesInfo;
          this.count = res.data.count;
          this.arctype = res.data.arctype;
          this.size = Number(res.data.page_limit);
          this.banner = res.data.ad;
          this.hot_limit = res.data.hot_limit;
		  this.format_arr = res.data.format_arr;
        }
      });
    },
    go(url, aid) {
      //vuex 存缓存
      this.$store.commit("setQuery", this.choose_type);
      this.$store.commit("setPage", this.page);
      // console.log(this.$store.getters.getPage);
      this.checkOriginalAuth(url, aid);
    },
    checkOriginalAuth(url, aid) {
      console.log(aid);
      checkOriginalAuth({
        aid: aid,
      }).then((res) => {
        console.log(res);
        if (res.code == 500) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          return;
        }
        if (res.code == 200) {
          this.$router.push({
            name: url,
            query: {
              aid: aid,
            },
          });
        }
      });
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    //选项
    change(val) {
      this.page = 1;
      this.getData();
    },
	changemodel(val) {
	  this.show_model = val;
	},
	changeformat(){
		this.page = 1;
		this.getData();
	},
  },
};
</script>

<style lang="less">
.page {
  background: #f6f6f6;

  .mid2 {
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .bottom2 {
    padding: 10px;

    .bottomimg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 100%;
        height: 100%;
		object-fit: contain;
      }
    }
    .bottomimg2 {
      position: relative;
	  width: 170px;
	  height: 170px;
	  margin-bottom: 10px;
    }
    .page {
      position: relative;
      left: -15%;
      transform: scale(0.7);
    }
  }
}
.poa {
  position: absolute;
  width: 26px !important;
  height: 30px !important;
  left: 6px;
  top: 8px;
  z-index: 3;
  img {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
}
.select{
	width: 24.5%;
	margin: 2px 0;
}
.select .el-input--suffix .el-input__inner{
	padding: 0 8px !important;
}
.page .bottom2 .bottomimg img.closeimg{
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.page .bottom2 .bottomimg img.logo1{
	position: absolute; 
	width: 38px;
	height: 38px;
	top: 63px;
	left: 59px;
	z-index: 1;
}
.bj{
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 0;
}
</style>
